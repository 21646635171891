import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiGetTicketOwener } from "../../api/tickets";

const host_url = process.env.REACT_APP_HOST_URL;

//actions
// TODO: tenant variable
export const fetchOwners = createAsyncThunk(
  "users/fetchOwners",
  async ({ actId, tkId,page,username }) => {
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    let res = await apiGetTicketOwener(tenant.key, actId, tkId,{page,username})
    console.log(res);
    return {data:res.data,page,username};
  }
);

const usersSlice = createSlice({
  name: "uesrs",
  initialState: {
    ticketsData:{},
    owners: []
  },
  reducers: {

  },
  extraReducers: {
    [fetchOwners.fulfilled]: (state, actions) => {
      console.log('succeeded', actions.payload);
      
      state.owners = actions.payload.data.results.map(el => {
        el.key = el.id
        return el
      })
      if(actions.payload.page==1&& !actions.payload.username){
        state.ticketsData = actions.payload.data
      }
    },
    [fetchOwners.rejected]: (state, actions) => {
      console.log('failed', actions.error.message);
    },
  },
});

//getter
export const owners = (state) => state.users.owners;
export const ticketsDataSelector = (state) => state.users.ticketsData;

//setter
// export const { setModalMsg } = usersSlice.actions;
export default usersSlice.reducer;
