import { useState, useEffect } from "react";
import {
  Typography,
  Table,
  Input,
  Space,
  Tag,
  Popconfirm,
  Button,
  Row,
  Spin,
} from "antd";
import moment from "moment";
import useBread from "../../components/breadcrumbBar/useBread";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOwners, owners, ticketsDataSelector } from "./uersSlice";
import {
  apiUpdateTicketStatus,
  apiGetTicketOwener,
} from "../../api/tickets.js";
import useDateFormat from "../../hooks/useDateFormat";
import { EditOutlined } from "@ant-design/icons";
import "./users.less";
import * as XLSX from "xlsx";

// const exportModal = () => {
//   return (
//     <Modal
//       title="Basic Modal"
//       open={isModalOpen}
//       onOk={handleOk}
//       onCancel={handleCancel}
//     ></Modal>
//   );
// };

const { Text, Title } = Typography;
const { Search } = Input;

const data = [];

const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD hh:mm");
};

for (let i = 1; i <= 100; i++) {
  data.push({
    key: i,
    userId: `user${i}`,
    ticketStatus: "可使用",
    exchanged_date: formatDate(new Date()),
    end_at: formatDate(new Date()),
    used_date: formatDate(new Date()),
  });
}
/* ticket status TODO:
    IS_NEW = 1
    # IS_ALLOCATED = 10
    IS_SENT = 20
    IS_RECEIVED = 30
    IS_USED = 40
     */

const Users = () => {
  useBread();
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [pageMeta, setPageMeta] = useState({ page: 1 });
  const dispatch = useDispatch();
  const params = useParams();
  const ownerList = useSelector(owners);
  const ticketsData = useSelector(ticketsDataSelector);
  const formatDate = useDateFormat();

  // Search
  // const [searchText, setSearchText] = useState(null);
  const getOwners = async () => {
    //conbineQuery
    let query = params;
    query = { ...params, ...pageMeta };
    console.log({ query });
    //fetch
    await dispatch(fetchOwners(query));
  };
  const onSearch = async (val) => {
    console.log("onSearch", val);
    if (val) {
      setPageMeta({ ...pageMeta, username: val });
    } else {
      setPageMeta({ ...pageMeta, username: null });
    }
  };
  const onShowSizeChange = (cur, pageSize) => {
    setPageMeta({ ...pageMeta, page: cur });
  };
  useEffect(() => {
    getOwners();
  }, [pageMeta]);
  //更改票券狀態
  const [toggleTkStatusText, setToggleTkStatusText] = useState("");
  const [targetInfo, setTargetInfo] = useState("");
  const [tenant] = useState(() => JSON.parse(localStorage.getItem("tenant")));
  const handleShowEditModal = (item) => {
    console.log({ item });
    let to = {};
    if (item.status == "1") {
      to = { val: "40", text: "已使用" };
    } else if (item.status == "40") {
      to = { val: "1", text: "未使用" };
    }
    setToggleTkStatusText(`你是否確定要將此票券狀態改為${to} ？`);
    setTargetInfo({ id: item.id, status: to.val });
  };
  const confirm = async () => {
    console.log("confirm", targetInfo);
    console.log({ params });
    try {
      let payload = {
        status: targetInfo.status,
      };
      let res = await apiUpdateTicketStatus(
        payload,
        params.tenant,
        params.actId,
        params.tkId,
        targetInfo.id
      );
      console.log({ res });
      await dispatch(fetchOwners(params));
    } catch (err) {
      console.log({ err });
    }
  };
  //Table Headers
  const columns = [
    {
      title: "Email",
      dataIndex: "owner_address",
      width: 550,
    },
    {
      title: "票券狀態",
      dataIndex: "status",
      width: 300,
      render: (status, item) => {
        let tkStatus = {};
        if (status == 1) {
          tkStatus = {
            text: "未使用",
            class: "actOff",
          };
        } else if (status == 40) {
          tkStatus = {
            text: "已使用",
            class: "actOn",
          };
        }
        return (
          <>
            <Tag className={tkStatus.class}>{tkStatus.text}</Tag>
            <Popconfirm
              placement="right"
              title={() => {
                let to = "";
                if (item.status == "1") to = "已使用";
                else if (item.status == "40") to = "未使用";
                return (
                  <p>
                    是否確定要將此票券狀態
                    <br />
                    改為『 <Text type="danger">{to}</Text> 』?
                  </p>
                );
              }}
              onConfirm={confirm}
              okText="更改狀態"
              cancelText="取消"
            >
              <a onClick={() => handleShowEditModal(item)}>
                <Text style={{ fontSize: "20px" }} className="info-text">
                  <EditOutlined />
                </Text>
              </a>
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "領取時間",
      dataIndex: "updated_at",
      width: 300,
      render: (updated_at) => {
        if (!updated_at) return "-";
        return `${formatDate(updated_at)}`;
      },
      // sorter: true,
      // sorter: (a, b) => a.date - b.date,
    },
    {
      title: "使用時間",
      dataIndex: "redeemed_at",
      render: (redeemed_at) => {
        if (!redeemed_at) return "-";
        return `${formatDate(redeemed_at)}`;
      },
      // sorter: true,
      // sorter: (a, b) => a.date - b.date,
    },
  ];
  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));
  const getAllTicket = () => {
    return new Promise(async (resolve) => {
      let exportData = [];
      if (ticketsData.count != 0) {
        let times = Math.ceil(ticketsData.count / ticketsData.results?.length);
        console.log({ times });
        for (let i = 1; i <= times; i++) {
          let res = await apiGetTicketOwener(
            params.tenant,
            params.actId,
            params.tkId,
            { page: i }
          );
          exportData = [...exportData, ...res.data.results];
        }
      }
      console.log({ exportData });
      let tableData = exportData.map((el) => {
        let obj = {
          Email: el.owner_address,
          票券狀態: el.status == 40 ? "已使用" : "未使用",
          領取時間: el.updated_at ? formatDate(el.updated_at) : "-",
          使用時間: el.redeemed_at ? formatDate(el.redeemed_at) : "-",
        };
        return obj;
      });
      resolve(tableData);
    });
  };
  const exportData = async () => {
    console.log("exportData");
    setExporting(true);
    let data = await getAllTicket();
    console.log("data from getAllTicket", { data });
    let workbook = XLSX.utils.book_new();
    let sheetName = "Sheet1";
    let worksheetData = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheetData, sheetName);
    const fileName = "票券細節.xlsx";
    XLSX.writeFile(workbook, fileName);
    setExporting(false);
  };
  return (
    <>
      <Title level={2} style={{ margin: "16px 0" }}>
        票券細節
      </Title>
      <Spin
        spinning={exporting}
        tip="匯出中，請稍候，請勿跳轉頁面"
        size="large"
      >
        <Row justify="space-between" style={{ marginBottom: "16px" }}>
          <Search
            placeholder="搜尋使用者Address"
            className="searchBar"
            onSearch={(e) => onSearch(e)}
            enterButton
            style={{
              width: 304,
            }}
          />
          <div className="text-right">
            <Button
              type="primary"
              ghost
              style={{ background: "#fff" }}
              onClick={exportData}
            >
              匯出
            </Button>
          </div>
        </Row>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            position: ["bottomCenter"],
            pageSize: ticketsData.count == 0 ? 10 : ticketsData.results?.length,
            showLessItems: true,
            total: ticketsData.count,
            onChange: onShowSizeChange,
          }}
          columns={tableColumns}
          dataSource={ownerList}
        />
      </Spin>
    </>
  );
};
export default Users;
