import { useState, useEffect } from "react";
import { Typography, Button, Space, Switch, Table, Radio, Form } from "antd";
import { DownOutlined, DeleteFilled } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import useBread from "../../components/breadcrumbBar/useBread";
import { useDispatch, useSelector } from "react-redux";
import { fetchTickets, ticketList } from "./ticketsSlice";
import useDateFormat from "../../hooks/useDateFormat";
import useGetTenant from "../../hooks/useGetTenant";

const { Text, Title } = Typography;
let actId = null;

const Tickets = () => {
  useBread();
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const tickets = useSelector(ticketList);
  const dispatch = useDispatch();
  const formatDate = useDateFormat();
  const { getTenant, tenantFromLS } = useGetTenant();
  const params = useParams();
  actId = params.actId;

  const columns = [
    {
      title: "票券種類",
      dataIndex: "title",
    },
    {
      title: "活動期間",
      dataIndex: "duration",
      render: (_, { start_at, end_at }) => (
        <span>
          {formatDate(start_at)} - <br />
          {formatDate(end_at)}
        </span>
      ),
    },
    {
      title: "總票券數",
      dataIndex: "limit",
      render: (val) => {
        if (val == 0) return "-";
        else return val;
      },
    },
    {
      title: "領取張數",
      dataIndex: "owner_cnt",
    },
    {
      title: "使用張數",
      dataIndex: "used_cnt",
    },
    {
      title: "票券使用率",
      dataIndex: "percentage",
      render: (val) => {
        if (!val) return "0%";
        return val + "%";
      },
    },
    {
      title: "更新時間",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <Text type="secondary" style={{ fontSize: "14px" }}>
          {formatDate(updated_at)}
        </Text>
      ),
      // sorter: true,
      // sorter: (a, b) => a.date - b.date,
    },
    {
      title: "操作",
      key: "action",
      // sorter: true,
      render: (props) => (
        <Space size="middle">
          <Button type="primary" shape="round">
            <Link
              to={`/acts/${tenantFromLS.key}/${actId}/${props.key}`}
              className="fontWeightBold btnFontSize"
            >
              查看細節
            </Link>
          </Button>
          {/* <a>
              <Text type='secondary'>
                  <DeleteFilled />
              </Text>
          </a> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getTenant();
    dispatch(fetchTickets(actId));
  }, []);

  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));
  return (
    <>
      <Title style={{ margin: "16px 0" }}>活動票券</Title>
      <Table
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 5,
          showLessItems: true,
        }}
        columns={tableColumns}
        dataSource={tickets}
      />
    </>
  );
};
export default Tickets;
